jQuery(document).ready(function($) {
    var sliderWidth = $('#sliderWidth').width();

    if (sliderWidth > 810) {
        sliderWidth = sliderWidth / 3;
        sliderWidth = sliderWidth - 30;
    } else if (sliderWidth > 530) {
        sliderWidth = sliderWidth / 2;
        sliderWidth = sliderWidth - 30;
    } else {
        sliderWidth = sliderWidth - 30;
    }


    $('.bxslider').bxSlider({
        slideWidth: sliderWidth,
        slideMargin: 30,
        maxSlides: 3,
        moveSlides: 1,
        hideControlOnEnd: true,
        nextText: '>',
        prevText: '<',
        //easing: 'ease',
        auto: false,
        pager: false,
        infiniteLoop: false
    });

    var role = '';
    $('#nb-prod').hide();
    $( "#reg-sales" ).change(function() {
        role = $(this).val();

        if (role === 'Sales') {
            $('#nb-prod').hide();
            $('#nb-sold').show();
        } else if (role === 'Production') {
            $('#nb-sold').hide();
            $('#nb-prod').show();
        } else {
            $('#nb-sold').show();
            $('#nb-prod').show();
        }
    });
    $('#mobile-burger').click(function () {
        $('#mobile-nav').show('slow');
    });

    $('#mobile-close').click(function () {
        $('#mobile-nav').hide('slow');
    });

    // Formulaire de contact
    var nature = '';
    $( "#input_3_11" ).change(function() {
        nature = $(this).val();
        if (nature === 'Demande de brochures' || nature === 'Petición de folletos' || nature === 'Richiesta brochure') {
            $('#contactImportant').show();
        } else {
            $('#contactImportant').hide();
        }
    });

});